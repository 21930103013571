<template>
  <van-pull-refresh
    style="height: 100%;overflow: scroll;"
    v-model="refreshing"
    @refresh="onRefresh"
  >
    <van-list
      v-model="isLoading"
      :finished="finished"
      :finished-text="$t('no-more')"
      @load="onLoad"
    >
      <ItemAdvertise v-for="item in list" :key="item.AdId" :item="item" />
    </van-list>
  </van-pull-refresh>
</template>
<script>
import ItemAdvertise from "./ItemAdvertise.vue";
import {AdvertiseGet} from "../../api/httpApi";
import {mapGetters} from "vuex";
export default {
  components: { ItemAdvertise },
  props: {
    select: {
      type: Number,
      default: 0,
    },
    coin: {
      type: Object,
      default() {
        return {}
      }
    },
  },
  computed: {
    ...mapGetters(["protocol"])
  },
  data() {
    return {
      isLoading: false,
      finished: false,
      refreshing: false,
      params: {
        Data: {
          Protocol: 0,
          Mold: 0,
          CoinId: 0,
          Nick: "",
          MinNum: 0,
          MinPrice: 0,
          MaxPrice: 0,
          CurrencyId: [],
          PayId: [],
          OrderBy: 0
        },
        Page: 1,
        Size: 20
      },
      list: [],
    };
  },
  methods: {
    async onLoad() {
      if (this.params.Data.CoinId <= 0) {
        this.refreshing = false;
        return
      }
      this.params.Data.Protocol = this.protocol
      const res = await AdvertiseGet(this.params)
      const list = res.data
      if (list.length < this.params.Size) {
        this.finished = true
      }
      if (this.params.Page === 1) {
        this.list = list
      } else {
        this.list = this.list.concat(list)
      }
      this.refreshing = false;
      this.params.Page++
    },
    onRefresh() {
      this.finished = false;
      this.loading = true;
      this.params.Page = 1;
      this.onLoad().then();
    },
    filterData(data) {
      const {coinId, sort, mold, name, minAmount, minPrice, maxPrice, fiat, pay} = data
      this.params.Data.OrderBy = sort
      this.params.Data.Mold = mold
      this.params.Data.CoinId = coinId
      this.params.Data.Nick = name
      this.params.Data.MinNum = minAmount
      this.params.Data.MinPrice = minPrice
      this.params.Data.MaxPrice = maxPrice
      this.params.Data.CurrencyId = fiat
      this.params.Data.PayId = pay
      this.onRefresh();
    }
  },
};
</script>
