<template>
  <div class="box">
    <div
        class="item"
        @click="tabClick(1)"
        :class="selectTab == 1 ? 'select' : ''"
    >
      {{ $t("BUY-COIN") }}
    </div>
    <div class="line"></div>
    <div
        class="item"
        @click="tabClick(0)"
        :class="selectTab == 0 ? 'select' : ''"
    >
      {{ $t("SELL-COIN") }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    firstTab: {
      type: Number,
      default: 1,
    },
    onTabChange: {
      type: Function,
      default: function (index) {
      },
    },
  },
  computed: {},
  data() {
    return {
      selectTab: this.firstTab,
    };
  },
  methods: {
    tabClick(index) {
      if (this.selectTab == index) {
        return;
      }
      this.selectTab = index;
      if (this.onTabChange) {
        this.onTabChange(index);
      }
    },
  },
};
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
}

.item {
  color: #999999;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  font-weight: 600;
}

.select {
  color: #010101;

}

.line {
  background-color: #999999;
  height: 51px;
  width: 3px;
  margin: 0 21px;
}

</style>
