<template>
    <div class="my-selected" :class="[padding ? 'padding' : '']" @click="popupClick">
  
      <div class="my-selected-box">
  
        <!-- <div class="my-selected-placeholder" v-if="arr.length === 0 && value.length === 0">{{placeholder}}</div> -->
  
        <!-- <template v-else>
          <div class="my-selected-list" v-if="multiple">
  
            <div class="my-selected-list-item" v-for="(item, index) in selectedActions" :key="index">
              <span>{{item.name}}</span>
              <img @click.stop="onCancelSelect(index)" class="my-selected-list-item-close" src="../../assets/img/close.png" alt="" />
            </div>
  
          </div>
  
          <div class="my-selected-label" v-else>
            {{selectedActions}}
          </div>
        </template> -->
  
        <img class="header-language" src="../../assets/img/language.png" />
      </div>
  
      <van-action-sheet
          v-model="popupShow"
          :actions="actions"
          cancel-text="取消"
          close-on-click-action
          @select="onSelect"
          @closed="onClosed"
          @close="onClose"
      />
  
    </div>
  </template>
  
  <script>
  export default {
    name: "index",
    model: {
      prop: 'value', // 对应 props msg
      event: 'change'
    },
    props: {
      multiple: Boolean, // 是否多选
      padding: Boolean, // 是否有padding
      value: [Number,String,Array],
      placeholder: String,
      actions: {
        type: Array,
        default() {
          return []
        }
      }
    },
    computed: {
      selectedActions() {
        if (this.multiple) {
          return this.actions.filter(item => {
            return this.value.indexOf(item.value) > -1
          })
        } else if (this.value){
          const item = this.actions.find(item => this.value == item.value) || {}
          return item.name
        }
        return ""
      }
    },
    data() {
      return {
        popupShow: false,
        isClose: false,
        arr: []
      }
    },
    methods: {
      onCancelSelect(index) {
        this.arr.splice(index, 1)
        this.$emit("change", this.arr)
      },
      popupClick() {
        console.log("选项")
        if (this.isClose) {
          return false
        }
        this.popupShow = true
      },
      onSelect(item) {
        if (this.multiple) {
          const index = this.arr.indexOf(item.value)
          if (index > -1) {
            this.arr.splice(index, 1)
          } else {
            this.arr.push(item.value)
          }
          this.$emit("change", this.arr)
          return false
        }
        this.arr = [item.value]
        this.$emit("change", this.arr[0])
      },
      onClose() {
        this.isClose = true
      },
      onClosed() {
        this.isClose = false
      },
    }
  }
  </script>
  
  <style lang="less" scoped>
  
  .my-selected {
    font-size: 32px;
    &.padding {
      padding: 20px 28px;
    }
  }
  
  .my-selected-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .my-selected-list {
    display: flex;
    align-items: center;
  }
  
  .my-selected-list-item {
    border: 2px solid rgba(61,61,61,0.53);
    border-radius: 8px;
    padding: 3px 12px;
    margin-right: 30px;
    position: relative;
  }
  
  .my-selected-list-item-close {
    position: absolute;
    right: -12px;
    top: -12px;
    width: 24px;
    height: 24px;
    background: #F1F1F1;
    border-radius: 12px;
    border: 1px solid rgba(61,61,61,0.53);
  }
  
  .my-selected-dropdown {
    //padding: 6px 12px;
    margin-left: 9px;
    width: 17px;
    height: 8px;
  }
  
  .my-selected-dropdown-img {
  }
  
  .my-selected-placeholder {
    color: #cccccc;
  }

  .header-language {
  width: 32px;
  height: 32px;
  // margin-right: 44px;
  /*padding: 12px;*/
}
  
  </style>