<template>
  <div class="fab-btn">
    <div class="fab-btn-mask" v-if="isShow" @click="tapSubmit"></div>
    <div class="fab-btn-sub">
      <img @click="tapSubmit" class="fab-btn-sub-img" :class="!isShow || 'close'" src="../assets/img/create.png" alt="" />
      <!--      <img @click="tapSubmit" v-else class="fab-btn-sub-img" src="../assets/img/close-full.png"/>-->

      <div class="fab-btn-list animation" :style="{ height: isShow ? (3 + 1) * 50 + 90 + 'px' : '50px' }"
        style="padding-top: 15px;padding-bottom: 20px;">

        <div class="fab-btn-item" @click="toCreate">
          <img class="fab-btn-item-img" src="../assets/img/add-ad.png" alt="">
          <div>{{ $t('publish') }}</div>
        </div>
        <div class="fab-btn-item" @click="toMyAd">
          <img class="fab-btn-item-img" src="../assets/img/ad.png" alt="">
          <div>{{ $t('me') }}</div>
        </div>
        <div class="fab-btn-item" @click="toOrderList">
          <img class="fab-btn-item-img" src="../assets/img/order.png" alt="">
          <div>{{ $t('order') }}</div>
        </div>

      </div>
    </div>
    <!-- title="温馨提示" confirmButtonText="注册商户" show-cancel-button-->
    <van-dialog v-model="registerShow" width="90%" :showConfirmButton="false" message="只有成为商户才能进入商户区，您要注册商户并进入商户区吗？"
      @confirm="toRegister">

      <div>
        <div class="title">{{ $t('tip-title') }}</div>
        <div class="tip_content">{{ $t('register_tip') }}</div>

        <div class="container-flex-h-center btn_container">

          <van-button class="btn_left" @click="registerShow = false">{{ $t("cancel") }}
          </van-button>

          <van-button class="btn_right" @click="toRegister">{{ $t("register_ad") }}
          </van-button>

        </div>
      </div>

    </van-dialog>

  </div>
</template>

<script>
import { } from "vant";
export default {
  props: {
    isRegister: Boolean
  },
  computed: {
    btnNumber() {
      return this.labels.length
    }
  },
  data() {
    return {
      isShow: false,
      registerShow: false,
    }
  },
  methods: {
    tapSubmit() {
      this.isShow = !this.isShow;
    },
    toCreate() {
      if (!this.isRegister) {
        this.registerShow = true
        return false
      }
      this.$router.push({ path: "/advertise-create" });
    },
    toRegister() {
      this.$router.push({ path: "/trader-register" });
    },
    toMyAd() {
      this.$router.push({ path: "/advertise-user-list" });
    },
    toOrderList() {
      this.$router.push({ path: "/order-user?source=trader" });
    }
  },
};
</script>

<style lang="less" scoped>
.title {
  font-size: 42px;
  font-weight: 500;
  color: #3665B7;
  margin-top: 50px;
  text-align: center;
}

.tip_content {
  font-size: 28px;
  font-weight: 500;
  color: #666666;
  margin-left: 40px;
  margin-right: 40px;
  margin-top: 56px;
  line-height: 40px;
}

.btn_right {
  flex: 1;
  height: 74px;
  border-radius: 20px;
  font-size: 30px;
  background-color: #3665B7;
  font-weight: 400;
  color: #FFFFFF;
}

.btn_left {
  flex: 1;
  height: 74px;
  border: 2px solid #3665B7;
  color: #3665B7;
  border-radius: 20px;
  margin-right: 60px;
  font-size: 30px;
  font-weight: 400;
}

.btn_container {
  margin: 56px 70px;
}

.fab-btn {
  position: fixed;
  bottom: 150px;
  right: 72px;
  z-index: 5;
}

.fab-btn-mask {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: transparent;
}

.fab-btn-sub {
  width: 120px;
  height: 120px;
  position: relative;
}

.fab-btn-sub-img {
  background-color: #12B4FF;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 10;
  transition: transform 0.4s;
}

.fab-btn-list {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #FFFFFF;
  box-sizing: border-box;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  border: 1px solid rgba(232, 232, 232, 0.8);
  box-shadow: 2px 0px 10px 0px rgba(206, 206, 206, 0.3);
  overflow: hidden;
}

.fab-btn-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
}

.fab-btn-item-img {
  width: 45px;
  height: 45px;
  margin-bottom: 6px;
}

.animation {
  transition-property: height;
  transition-duration: 0.2s;
}

.close {
  transform: rotate(135deg);
}
</style>

