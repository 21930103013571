<template>
  <div class="m-input" :class="[type ? 'm-input-' + type : '', size ? size : '', {'m-input-plain': plain}]">
    <!-- 前置元素 -->
    <div class="m-input__prefix-inner">
      <slot name="prefix"></slot>
    </div>
    <div class="m-input__box" :class="[size ? size : '']">
      {{ label }}
      <input :type="inputType"
             :focus="focus"
             class="m-input__inner"
             autocomplete="off"
             :value="value"
             @input="$emit('change', $event.target.value)"
             :disabled="disabled"
             :placeholder="placeholder"
             :class="[placeholderClass]">
    </div>
    <!-- 后置内容 -->
    <div class="m-input__suffix-inner">
      <slot name="suffix"></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "m-input",
  model: {
    prop: 'value', // 对应 props msg
    event: 'change'
  },
  props: {
    focus: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ""
    },
    inputType: {
      type: String,
      default: "text"
    },
    type: {
      type: String,
      default: "default"
    },
    placeholder: {
      type: String,
      default: "请输入内容"
    },
    placeholderClass: {
      type: String,
      default: ""
    },
    label: {
      type: String,
      default: ""
    },
    plain: {
      type: Boolean,
      default: false
    },
    size: {
      type: String,
      default: "default"
    },
  },
  methods: {
  }
};
</script>

<style lang="less" scoped>

.m-input {
  position: relative;
  font-size: 24px;
  border-radius: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #F5F5F5;
  &.mini {
    .m-input__inner {
      font-size: 24px;
    }
  }

  .m-input__box {
    display: flex;
    flex-direction: row;
    flex: 1;
    align-items: center;
    padding: 16px 25px;
    &.mini {
      padding: 8px 24px;
    }
  }

  &.m-input-plain {
    padding-top: 0;
    padding-bottom: 0;
  }

  &.m-input-default {
    color: #000000;
  }

  .m-input__inner {
    width: 100%;
    font-size: 24px;
    background-color: rgba(0, 0, 0, 0);
  }
}
</style>
