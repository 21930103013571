<template>
  <div class="ad-item">

    <div class="ad-header">

      <div class="ad-header-user">
        <my-avatar class="ad-header-user-avatar" size="mini" :img="item.MerchantInfo.Head" :text="getIcon"></my-avatar>
        <div class="ad-header-user-name">{{item.MerchantInfo.Nick}}</div>
        <div class="ad-header-user-address-box">(<span class="ad-header-user-address">{{item.MerchantInfo.Address}}</span>)</div>
      </div>
      <div class="ad-header-num">成单量 {{item.MerchantInfo.DealOrder}} | {{DealRate}}%</div>
    </div>

    <div class="ad-content">

      <div class="ad-content-item">
        <div class="ad-price-text">单价</div>
        <div class="ad-content-fait">{{item.CurrencyInfo.EnName}}</div>
      </div>

      <div class="ad-content-item">
        <div class="ad-price"><span class="ad-price-symbol">{{item.CurrencyInfo.Symbol}}</span>{{item.Price}}</div>
      </div>

      <div class="ad-content-item ad-content-item-h10">
        <div class="ad-content-num">
          <div class="ad-content-amount-box">
            <div class="ad-content-amount-text">剩余数量</div>
            <div class="ad-content-amount">{{item.Num - item.Deal - item.Pending}} {{item.CoinName}}</div>
          </div>
          <div class="ad-content-money-box">
            <div class="ad-content-money-text">限额</div>
            <div class="ad-content-money">{{item.CurrencyInfo.Symbol}}{{item.Min <= 0 ? '不限额' : item.Min}} ~ {{item.CurrencyInfo.Symbol}}{{item.Max <= 0 ? '不限额' : item.Max}}</div>
          </div>
        </div>
        <div class="ad-content-buy" @click="itemClick">{{ item.Mold === 1 ? '卖出' : '购买' }}</div>
      </div>

      <div class="ad-content-item ad-content-item-h20">
        <div class="ad-content-item-pay-list">
          <div class="ad-content-item-pay-list-item" v-for="p in item.PaymentInfo" :key="p.Id">
            <span class="ad-content-item-pay-list-item-line" :style="{color: p.Color}"></span>
            <span>{{p.EnName}}</span>
          </div>
        </div>
      </div>

    </div>

  </div>

</template>
<script>
import MyAvatar from "../../components/my-avatar";

export default {
  name: "ItemAdvertise",
  components: {
    MyAvatar
  },
  props: {
    item: {
      type: Object,
      default() {
        return {
          MerchantInfo: {
            Address: "",
            Nick: "",
            Head: "",
            TotalOrder: 0,
            DealOrder: 0,
            ErrorOrder: 0,
            RevokeOrder: 0,
            LoadOrder: 0,
            SessionId: ""
          },
        }
      }
    }
  },
  computed: {
    DealRate() {
      if (this.item.MerchantInfo.TotalOrder <= 0) {
        return 0.00
      }
      return Number(this.item.MerchantInfo.DealOrder / this.item.MerchantInfo.TotalOrder * 100).toFixed(2)
    },
    getIcon() {
      return this.item.MerchantInfo.Nick.substring(0, 1)
    },
  },
  methods: {
    itemClick() {
      console.dir("click");
      this.$router.push({ path: `/advertise-detail?adId=${this.item.AdId}` });
    },
  },
};
</script>
<style lang="less" scoped>

.ad-item {
  padding: 36px 30px 18px;
  border-bottom: 4px solid #F2F2F2;
}

.ad-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad-header-user {
  display: flex;
  align-items: center;
}

.ad-header-user-avatar {
  margin-right: 11px;
}

.ad-header-user-name {
  color: #333333;
  font-weight: bold;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width: 100px;
}

.ad-header-user-address-box {
  display: flex;
  margin-left: 6px;
}

.ad-header-user-address {
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  max-width: 100px;
  direction: rtl;
  color: #666666;
}

.ad-header-num {
  color: #666666;
}

.ad-content {
  margin-top: 40px;
  padding: 0 5px;
}

.ad-content-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ad-content-item-h10 {
  margin-top: 10px;
}

.ad-content-item-h20 {
  margin-top: 20px;
}

.ad-content-item-bottom {
  align-items: flex-end;
}

.ad-price-text {
  color: #999999;
}

.ad-content-fait {
  background-color: #E7AF4F;
  border-radius: 10px;
  padding: 0 17px;
  color: #FFFFFF;
}

.ad-price {
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: #000000;
  font-size: 48px;
  font-weight: 800;
}

.ad-price-symbol {
  padding-top: 6px;
  padding-right: 6px;
  margin-left: -5px;
  font-size: 26px;
}

.ad-content-num {
}

.ad-content-amount-box {
  display: flex;
  align-items: center;
}

.ad-content-amount-text {
  color: #999999;
  font-size: 24px;
  margin-right: 19px;
}

.ad-content-amount {
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}

.ad-content-money-box {
  display: flex;
  align-items: center;
}

.ad-content-money-text {
  color: #999999;
  font-size: 24px;
  margin-right: 19px;
}

.ad-content-money {
  color: #333333;
  font-size: 26px;
  font-weight: bold;
}

.ad-content-buy {
  color: #FFFFFF;
  font-size: 30px;
  background-color: #3665B7;
  padding: 10px 48px;
  border-radius: 10px;
}

.ad-content-item-pay-list {
  color: #333333;
  font-size: 24px;
  display: flex;
  align-items: center;
}

.ad-content-item-pay-list-item {
  display: flex;
  align-items: center;
  margin-right: 30px;
}

.ad-content-item-pay-list-item-line {
  //margin-top: 2px;
  width: 3px;
  height: 22px;
  background-color: #03F474;
  margin-right: 10px;
}

</style>
