<template>
  <div class="content">
    <div class="tab-box container-flex-h">
      <div class="container-flex-h-order tab-bg">
        <div class="tab-item" v-for="(item, index) in getCoin" :key="index" @click="tabClick(index)"
          :class="coin.select == index ? 'tab-item-select ' : ''">
          <p>{{ item.EnName }}</p>
        </div>
      </div>
      <div class="container-flex-h-order">
        <!-- sort  -->
        <div class="sort">
          <van-dropdown-menu>
            <van-dropdown-item title="价格" ref="sort" v-model="sortValue">
              <div class="home-price-sort">
                <div class="home-price-sort-item" v-for="item in sortArr" :key="item.value"
                  :class="[item.value === sortValue ? 'selected' : '']" @click="sortChange(item.value)">
                  <div class="home-price-sort-item-text">
                    <div>{{ item.text }}</div>
                    <template v-if="item.type === 'up'">
                      <img v-if="item.value === sortValue" src="../../assets/img/sort-up-selected.png" alt=""
                        class="home-price-sort-item-text-img">
                      <img v-else src="../../assets/img/sort-up.png" alt="" class="home-price-sort-item-text-img">
                    </template>
                    <template v-else>
                      <img v-if="item.value === sortValue" src="../../assets/img/sort-down-selected.png" alt=""
                        class="home-price-sort-item-text-img">
                      <img v-else src="../../assets/img/sort-down.png" alt="" class="home-price-sort-item-text-img">
                    </template>
                  </div>
                  <div class="home-price-sort-item-selected">
                    <img v-if="item.value === sortValue" src="../../assets/img/sort-selected.png" alt=""
                      class="home-price-sort-item-selected-img">
                  </div>
                </div>
              </div>
            </van-dropdown-item>
            <van-dropdown-item title="筛选" ref="filter">
              <div class="home-sort">
                <div class="sort-item">
                  <div class="sort-item-label">商户</div>
                  <div class="sort-item-content">
                    <my-input class="sort-input" placeholder-class="sort-input-placeholder" v-model="filterData.name"
                      placeholder="请输入商户地址或商户名"></my-input>
                  </div>
                </div>
                <div class="sort-item">
                  <div class="sort-item-label">价格</div>
                  <div class="sort-item-content">
                    <my-input class="sort-input" placeholder-class="sort-input-placeholder"
                      v-model="filterData.maxPrice" placeholder="请输入最低价"></my-input>
                    <div class="sort-line">
                      <img src="../../assets/img/line.png" alt="" class="sort-line-img">
                    </div>
                    <my-input class="sort-input" placeholder-class="sort-input-placeholder"
                      v-model="filterData.maxPrice" placeholder="请输入最高价"></my-input>
                  </div>
                </div>
                <div class="sort-item">
                  <div class="sort-item-label">数量</div>
                  <div class="sort-item-content">
                    <my-input class="sort-input" placeholder-class="sort-input-placeholder"
                      v-model="filterData.minAmount" placeholder="请输入最低剩余数量"></my-input>
                  </div>
                </div>
                <div class="sort-item sort-item-m60" style="align-items: flex-start">
                  <div class="sort-item-label">法币</div>
                  <div class="sort-item-content">
                    <my-check-box v-model="filterData.fiat" :actions="currencyList"></my-check-box>
                  </div>
                </div>
                <div class="sort-item sort-item-m40" style="align-items: flex-start">
                  <div class="sort-item-label">途径</div>
                  <div class="sort-item-content">
                    <my-check-box v-model="filterData.pay" :actions="paymentList"></my-check-box>
                  </div>
                </div>
                <div class="sort-btn-box">
                  <div class="sort-btn line" @click="reset">重置</div>
                  <div class="sort-btn" @click="filter">筛选</div>
                </div>
              </div>
            </van-dropdown-item>
          </van-dropdown-menu>
        </div>

      </div>
    </div>
    <div class="list-box">
      <List ref="page" :coin="getCoin[coin.select]" />
    </div>
  </div>
</template>
<script>
import List from "./List.vue";
import MyInput from "../../components/my-input/input";
import MyCheckBox from "../../components/my-checkbox";
import { mapGetters } from "vuex";
import { CurrencyGet, PaymentGet } from "../../api/httpApi";
export default {
  components: { List, MyInput, MyCheckBox },
  props: {
    type: {
      type: String,
      default: "BUY",
    },
  },
  computed: {
    ...mapGetters(["protocol", "coinList"]),
    getCoin() {
      const protocol = this.coinList.find(item => item.Protocol == this.protocol) || {}
      const arr = this.type === "BUY" ? protocol.SellCoin : protocol.BuyCoin
      return arr || []
    },
  },
  created() {
    this.getPayment()
    this.getCurrency()
  },
  data() {
    return {
      isLoading: false,
      finished: false,
      refreshing: false,
      sortValue: 1,
      sortArr: [
        { text: '价格降序', value: 1, type: 'down' },
        { text: '价格升序', value: 2, type: 'up' },
        { text: '时间降序', value: 3, type: 'down' },
        { text: '时间升序', value: 4, type: 'up' },
      ],
      coin: {
        select: 0,
      },
      filterData: {
        name: "",
        minPrice: "",
        maxPrice: "",
        minAmount: "",
        fiat: [],
        pay: [],
      },
      paymentList: [],
      currencyList: [],
    };
  },
  methods: {
    reset() {
      console.dir("reset");
      this.filterData = {
        name: "",
        minPrice: "",
        maxPrice: "",
        minAmount: "",
        fiat: [],
        pay: [],
      }
      this.$refs.filter.toggle()
      this.loadData(this.type)
    },
    filter() {
      this.$refs.filter.toggle()
      this.loadData(this.type)
    },
    tabClick(index) {
      this.coin.select = index
      this.loadData(this.type)
    },
    toAdUserList() {
      this.$router.push({ path: "/advertise-user-list" });
    },
    sortChange(value) {
      this.$refs.sort.toggle()
      this.sortValue = value
      this.loadData(this.type)
    },
    loadData(type) {
      const coin = this.getCoin[this.coin.select] || {}
      const coinId = coin.Id || 0
      this.$refs.page.filterData({
        coinId: coinId,
        sort: this.sortValue || 1,
        mold: type === 'BUY' ? 0 : 1,
        name: this.filterData.name || "",
        minAmount: this.filterData.minAmount || 0,
        minPrice: this.filterData.minPrice || 0,
        maxPrice: this.filterData.maxPrice || 0,
        fiat: this.filterData.fiat || [],
        pay: this.filterData.pay || [],
      })
    },
    getPayment() {
      const data = {}
      PaymentGet(data)
        .then(res => {
          if (res.status > 1) {
            return false
          }
          const list = res.data
          this.paymentList = list.map(item => {
            return {
              value: item.Id,
              text: item.Name,
            }
          })
        }).catch((err) => {
          console.dir(err)
        })
    },
    getCurrency() {
      const data = {}
      CurrencyGet(data)
        .then(res => {
          if (res.status > 1) {
            return false
          }
          const list = res.data
          if (list.length === 0) {
            return false
          }
          this.currencyList = list.map(item => {
            return {
              value: item.Id,
              text: item.EnName,
            }
          })
        }).catch((err) => {
          console.dir(err)
        })
    },
  },
  mounted() {
  },
};
</script>

<style lang="less" scoped>
.content {
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  flex-basis: 0;
}

.tab-box {
  align-items: center;
  padding: 0 30px;
  background-color: #F2F2F2;
}

.list-box {
  overflow: scroll;
  height: 100%
}

.seckilling-tab .van-tabs__content {
  height: 100%;
}

.tab-item {
  font-size: 29px;
  font-weight: 600;
  color: #666666;
  margin-right: 40px;
  padding-top: 20px;
  margin-bottom: 20px;
}

.tab-item-select {
  border-bottom: 4px solid #3665B7;
  color: #3665B7;
  font-weight: 400;
}

.tab-bg {
  overflow: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.tab-bg::-webkit-scrollbar {
  display: none;
}
</style>

<style lang="less">
.home-price-sort {
  padding: 9px 30px;
  color: #666666;
  font-size: 26px;

  .home-price-sort-item {
    padding: 17px 0;
    display: flex;
    justify-content: space-between;

    &.selected {
      color: #E7AF4F;
    }
  }

  .home-price-sort-item-text {
    display: flex;
    align-items: center;
  }

  .home-price-sort-item-text-img {
    width: 21px;
    height: 12px;
    margin-left: 20px;
  }

  .home-price-sort-item-selected-img {
    width: 32px;
    height: 22px;
  }
}

.home-sort {
  padding: 10px 0 30px;

  .sort-item {
    margin-top: 30px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    font-size: 32px;
    color: #666666;
  }

  .sort-item-m60 {
    margin-top: 60px;
  }

  .sort-item-m40 {
    margin-top: 40px;
  }

  .sort-item-label {
    color: #666666;
    font-weight: bold;
    margin-right: 24px;
    white-space: nowrap;
  }

  .sort-input {
    flex: 1;
  }

  .sort-input-placeholder {
    &::placeholder {
      color: #BDBDBD;
    }
  }

  .sort-line {
    padding: 0 25px;
  }

  .sort-line-img {
    width: 33px;
    height: 2px;
  }

  .sort-item-content {
    flex: 1;
    display: flex;
    justify-content: space-between;
    overflow-x: hidden;
  }

  .sort-btn-box {
    margin-top: 70px;
    margin-bottom: 48px;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .sort-btn {
    font-size: 28px;
    width: 45%;

    &+.sort-btn {
      margin-left: 58px;
    }

    display: flex;
    justify-content: center;
    align-items: center;
    padding: 18px 0;
    background-color: #3665B7;
    border-radius: 20px;
    color: #FFFFFF;

    &.line {
      color: #3665B7;
      border: 1px solid #3665B7;
      background-color: transparent;
    }
  }
}
</style>
