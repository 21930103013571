<template>
  <div style="height: 100%; display: flex; flex-direction: column">
    <!-- header -->
    <div class="header-box">
      <div class="header">

        <!-- center -->
        <BuySellTab :first-tab="sellBuyType" :onTabChange="onSellBuytabChange" />

        <!-- right  -->
        <div class="header-right">

          <div class="chain-box">
            <my-selected :value="protocol" :actions="coinListArr" @change="onChainChange">
            </my-selected>
          </div>

          <!-- <img class="header-language" src="../assets/img/language.png" /> -->
          <div class="chain-box">
            <laguage-select :value="language" :actions="languages" @change="onLanguageSelect">
            </laguage-select>
          </div>

          <img class="header-menu" @click="toOrderList" src="../assets/img/menu.png" />

        </div>
      </div>
    </div>

    <!-- content -->
    <!-- <div style="height: 100%;"> -->
    <div class="body">
      <TabCompent ref="loadData" :type="sellBuyType == 1 ? 'BUY' : 'SELL'" />
    </div>

    <my-fab-btn :isRegister="isRegister"></my-fab-btn>
  </div>
</template>
<script>
import BuySellTab from "../components/BuySellTab.vue";
import TabCompent from "./advertise/TabCompent.vue";
import MyFabBtn from "../components/MyFabBtn";
import { HomeInfo } from "../api/home";
import { getAccounts } from "../utils/wallet";
import { mapGetters, mapActions } from "vuex";
import MySelected from "../components/my-selected";
import LaguageSelect from "../components/laguage-select";
export default {
  name: "AdvertiseList",
  components: {
    MyFabBtn,
    BuySellTab,
    TabCompent,
    MySelected,
    LaguageSelect
  },
  computed: {
    ...mapGetters(["protocol", "coinList", "isRegister", "language", "languages"]),
    protocolName() {
      const protocol = this.coinList.find(item => item.Protocol == this.protocol) || {}
      return protocol.EnName || ""
    },
    coinListArr() {
      const arr = this.coinList.map(item => {
        return {
          value: item.Protocol,
          name: item.EnName,
        }
      })
      return arr
    }
  },
  data() {
    return {
      infoLoading: false,
      info: {
        inviter: '0x0000000000000000000000000000000000000000'
      },
      sellBuyType: 1,
    };
  },
  mounted() {
    this.getAuthorizeInfo().then()
  },
  methods: {
    ...mapActions(["setProtocol", "setIsRegister", "setLanguage"]),
    onSellBuytabChange(index) {
      this.sellBuyType = index;
      this.$refs.loadData.loadData(this.sellBuyType == 1 ? 'BUY' : 'SELL')
    },
    onChainChange(value) {
      const protocol = value || ""
      if (this.protocol === protocol) {
        return;
      }
      this.setProtocol(protocol)
      // refresh data
      this.$refs.loadData.loadData(this.sellBuyType == 1 ? 'BUY' : 'SELL')
    },
    onLanguageSelect(value) {
      this.setLanguage(value);
    },
    async getAuthorizeInfo() {
      const accounts = await getAccounts()
      this.account = accounts[0]
      await this.getHomeInfo()
    },
    async getHomeInfo() {
      if (this.infoLoading) {
        return false
      }
      this.infoLoading = true
      this.info = await HomeInfo(this.account)
      this.infoLoading = false
      this.setIsRegister(this.info.inviter !== '0x0000000000000000000000000000000000000000')
      this.$refs.loadData.loadData(this.sellBuyType == 1 ? 'BUY' : 'SELL')
    },
    toOrderList() {
      this.$router.push({ path: "/order-user" });
    },
  },
};
</script>

<style scoped>
.header-box {
  padding-top: 30px;
  background-color: #F2F2F2;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 42px 32px 30px;
  border-radius: 50px 50px 0 0;
  background-color: #FFFFFF;
}

.header-right {
  display: flex;
  align-items: center;
}

.chain-box {
  margin-right: 44px;
}

.header-language {
  width: 32px;
  height: 32px;
  margin-right: 44px;
  /*padding: 12px;*/
}

.header-menu {
  width: 32px;
  height: 32px;
}

.body {
  flex: 1;
}
</style>
