<template>
  <div class="my-checkbox">

    <div class="my-checkbox-list" :class="[!isShow ? 'my-checkbox-hidden' : '']">
      <div class="my-checkbox-item" @click.stop="itemClick(item)" :class="[selected(item.value) ? 'selected' : '']" v-for="item in actions" :key="item.value">
        {{item.text}}
        <img v-if="selected(item.value)" class="my-checkbox-item-selected-img" src="../../assets/img/checkbox-selected-icon.png" alt="">
      </div>
    </div>

    <div class="my-checkbox-more" @click.stop="changeShow">{{isShow ? '+ 更多' : '- 收起'}}</div>

  </div>
</template>

<script>
export default {
  name: "index",
  model: {
    prop: 'value', // 对应 props msg
    event: 'change'
  },
  props: {
    actions: {
      type: Array,
      default() {
        return []
      }
    }
  },
  computed: {
    selected() {
      return (value) => {
        return this.arr.indexOf(value) > -1
      }
    }
  },
  data() {
    return {
      isShow: false,
      arr: []
    }
  },
  methods: {
    itemClick(item) {
      const index = this.arr.indexOf(item.value)
      if (index > -1) {
        this.arr.splice(index, 1)
      } else {
        this.arr.push(item.value)
      }
      this.$emit("change", this.arr)
    },
    changeShow() {
      this.isShow = !this.isShow
    }
  }
}
</script>

<style lang="less" scoped>

.my-checkbox {
  flex: 1;
  position: relative;
}

.my-checkbox-list {
  overflow: hidden;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  margin: -24px 0 0 -32px;
  transition-property: height;
  transition-duration: 0.5s;
  &.my-checkbox-hidden {
    height: 87px;
  }
}

.my-checkbox-item {
  margin: 24px 0 0 32px;
  padding: 7px 35px;
  color: #666666;
  font-size: 25px;
  border: 4px solid #999999;
  border-radius: 10px;
  &.selected {
    border: 4px solid #779CDC;
    color: #779CDC;
  }
  position: relative;
}

.my-checkbox-item-selected-img {
  width: 48px;
  height: 38px;
  position: absolute;
  bottom: -2px;
  right: -2px;
}

.my-checkbox-in {
  font-size: 25px;
  padding: 10px 0;
  margin: 24px 0 0 32px;
  color: #999999;
}

.my-checkbox-more {
  position: absolute;
  right: 0;
  top: 0;
  font-size: 25px;
  color: #999999;
  padding: 10px 0;
}

</style>