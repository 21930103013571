import {getTraderAddress, getUSDTAddress} from "../utils/addressHelpers";

import TraderAbi from "../config/abi/Trader.json"
import Erc20Abi from "../config/abi/erc20.json"
import {multicallAllv2} from "../utils/multicall";
import {getTraderContract} from "../utils/contractHelpers";
import {MAX_INT} from "../config";
import BigNumber from "bignumber.js";

export const HomeInfo = async (account) => {

    const traderAddress = getTraderAddress()

    const calls = [
        {
            address: traderAddress,
            name: "inviter",
            params: [account],
            abiIndex: 0,
        }
    ]

    const abis = [
        TraderAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)

    return {
        inviter: raw[0][0],
    }
}

export const RegisterInfo = async (account) => {

    const usdtAddress = getUSDTAddress()
    const traderAddress = getTraderAddress()

    const calls = [
        {
            address: usdtAddress,
            name: "allowance",
            params: [account,traderAddress],
            abiIndex: 0,
        },
        {
            address: usdtAddress,
            name: "balanceOf",
            params: [account],
            abiIndex: 0,
        },
        {
            address: traderAddress,
            name: "defaultBail",
            params: [],
            abiIndex: 1,
        },
    ]

    const abis = [
        Erc20Abi,
        TraderAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)
    const max = new BigNumber(MAX_INT)
    return {
        approve: new BigNumber(raw[0].toString()).comparedTo(max) >= 0,
        balance: new BigNumber(raw[1].toString()),
        defaultBail: new BigNumber(raw[2].toString()),
        usdtDecimals: new BigNumber(1e18),
    }
}

export const Register = async (superior, amount, data) => {

    let Base64 = require('js-base64').Base64;
    const _data = JSON.stringify(data)
    const dataBytes = Base64.encode(_data);

    const contract = getTraderContract(true)

    try {
        const tx = await contract.register(superior, amount, dataBytes)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}


export const CenterInfo = async (account) => {

    const traderAddress = getTraderAddress()

    const calls = [
        {
            address: traderAddress,
            name: "traderList",
            params: [account],
            abiIndex: 0,
        },
    ]

    const abis = [
        TraderAbi,
    ]

    const raw = await multicallAllv2(abis, calls, false)

    const _data = raw[0]["data"]
    let name = "",tel = ""
    if (_data) {
        try {
            let Base64 = require('js-base64').Base64;
            const json = JSON.parse(Base64.decode(_data))
            name = json.name
            tel = json.tel
        }catch (e) {

        }
    }
    return {
        freeze: new BigNumber(raw[0]['freeze'].toString()),
        validation: raw[0]['validation'].toString() == 1 ? true : false,
        status: raw[0]['status'].toString() == 1 ? true : false,
        bail: new BigNumber(raw[0]['bail'].toString()),
        name: name,
        tel: tel,
        usdtDecimals: new BigNumber(1e18),
    }
}

export const TraderUpdateData = async (data) => {

    let Base64 = require('js-base64').Base64;
    const _data = JSON.stringify(data)
    const dataBytes = Base64.encode(_data);

    const contract = getTraderContract(true)

    try {
        const tx = await contract.setTraderData(dataBytes)
        const res = await tx.wait()
        if (res.status !== 1) {
            return false
        }
        return res
    } catch (e) {
        console.log(e)
        return false
    }

}